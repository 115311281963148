table {
	width: 100%;
	margin-bottom: 5rem;
}

.inventory-table {
	border: 1px solid #e5e5e5;
	thead {
		background-color: #e5e5e5;
		tr {
			height: 50px;
		}
	}
	tbody {
		tr {
			background-color: #eeeeee;
		}
	}
}

.inventry-row {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.manual-order {
	display: flex;
	flex-direction: row;
}

.user-details {
	width: 60%;
	.wrapper {
		margin-top: 5%;
		width: 90%;
		display: flex;
		justify-content: center;
		font-family: "Arimo";
		background-color: #eb9478;
		-webkit-box-shadow: 9px 13px 25px 0px rgba(0, 0, 0, 0.18);
		-moz-box-shadow: 9px 13px 25px 0px rgba(0, 0, 0, 0.18);
		box-shadow: 9px 13px 25px 0px rgba(0, 0, 0, 0.18);
		animation: slideUp 2000ms ease;
	}

	@keyframes slideUp {
		0% {
			-webkit-transform: translateY(100%);
			transform: translateY(100%);
			visibility: visible;
		}
		100% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
	}
	.container {
		width: 65%;
		padding: 5% 10%;
	}

	h1 {
		align-self: center;
	}

	form {
		width: 100%;
	}
	form > * {
		margin-top: 20px;
	}
	form input {
		width: 100%;
		min-height: 25px;
		border: 0;
		font-size: 1rem;
		letter-spacing: 0.15rem;
		font-family: "Arimo";
		margin-top: 5px;
		color: #8e2807;
		border-radius: 4px;
	}
	form label {
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 2px;
		color: #8e2807;
	}
	form h1 {
		font-size: 24px;
		line-height: 10px;
		color: #493b76;
		letter-spacing: 1px;
	}
	form h1:nth-of-type(2) {
		margin-top: 10%;
	}

	.name {
		justify-content: space-between;
		display: flex;
		width: 100%;
	}
	.name div {
		width: 45%;
	}

	.address-info {
		display: flex;
		justify-content: space-between;
	}
	.address-info div {
		width: 30%;
	}

	.cc-info {
		display: flex;
		justify-content: space-between;
	}
	.cc-info div {
		width: 45%;
	}

	.btns {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.btns button {
		margin: 3px 0;
		height: 30px;
		width: 60%;
		margin-left: 40%;
		color: #cfc9e1;
		background-color: #4a3b76;
		text-transform: uppercase;
		border: 0;
		border-radius: 0.3rem;
		letter-spacing: 2px;
	}
	.btns button:hover {
		animation-name: btn-hov;
		animation-duration: 550ms;
		animation-fill-mode: forwards;
	}

	@keyframes btn-hov {
		100% {
			background-color: #cfc9e1;
			color: #4a3b76;
			transform: scale(1.05);
		}
	}
	input:focus,
	button:focus {
		outline: none;
	}

	@media (max-width: 736px) {
		.wrapper {
			width: 100%;
		}

		.container {
			width: 100%;
		}

		.btns {
			align-items: center;
			display: flex;
			flex-direction: row;
		}
		.btns button {
			width: 50%;
		}

		form h1 {
			text-align: center;
		}

		.name,
		.address-info,
		.cc-info {
			flex-direction: column;
			width: 100%;
			justify-content: space-between;
		}
		.name div,
		.address-info div,
		.cc-info div {
			align-items: center;
			flex-direction: column;
			width: 100%;
			display: flex;
		}

		.street,
		.cc-num {
			text-align: center;
		}

		input {
			margin: 5px 0;
			min-height: 30px;
		}
	}
}

.order-details {
	width: 40%;
	.wrapper {
		margin-top: 5%;
		width: 90%;
		display: flex;
		justify-content: center;
		font-family: "Arimo";
		background-color: #eb9478;
		-webkit-box-shadow: 9px 13px 25px 0px rgba(0, 0, 0, 0.18);
		-moz-box-shadow: 9px 13px 25px 0px rgba(0, 0, 0, 0.18);
		box-shadow: 9px 13px 25px 0px rgba(0, 0, 0, 0.18);
		animation: slideUp 2000ms ease;
	}

	@keyframes slideUp {
		0% {
			-webkit-transform: translateY(100%);
			transform: translateY(100%);
			visibility: visible;
		}
		100% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
	}
	.container {
		width: 65%;
		padding: 5% 10%;
	}

	h1 {
		align-self: center;
	}

	form {
		width: 100%;
	}
	form > * {
		margin-top: 20px;
	}
	form input {
		width: 100%;
		min-height: 25px;
		border: 0;
		font-size: 1rem;
		letter-spacing: 0.15rem;
		font-family: "Arimo";
		margin-top: 5px;
		color: #8e2807;
		border-radius: 4px;
	}
	form label {
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 2px;
		color: #8e2807;
	}
	form h1 {
		font-size: 24px;
		line-height: 10px;
		color: #493b76;
		letter-spacing: 1px;
	}
	form h1:nth-of-type(2) {
		margin-top: 10%;
	}

	.name {
		justify-content: space-between;
		display: flex;
		width: 100%;
	}
	.name div {
		width: 45%;
	}

	.address-info {
		display: flex;
		justify-content: space-between;
	}
	.address-info div {
		width: 30%;
	}

	.cc-info {
		display: flex;
		justify-content: space-between;
	}
	.cc-info div {
		width: 45%;
	}

	.btns {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.btns button {
		margin: 3px 0;
		height: 30px;
		width: 60%;
		margin-top: 5%;
		color: #cfc9e1;
		background-color: #4a3b76;
		text-transform: uppercase;
		border: 0;
		border-radius: 0.3rem;
		letter-spacing: 2px;
	}
	.btns button:hover {
		animation-name: btn-hov;
		animation-duration: 550ms;
		animation-fill-mode: forwards;
	}

	.upload-csv {
		width: 60%;
		margin-left: 10% !important;
	}

	@keyframes btn-hov {
		100% {
			background-color: #cfc9e1;
			color: #4a3b76;
			transform: scale(1.05);
		}
	}
	input:focus,
	button:focus {
		outline: none;
	}

	@media (max-width: 736px) {
		.wrapper {
			width: 100%;
		}

		.container {
			width: 100%;
		}

		.btns {
			align-items: center;
		}
		.btns button {
			width: 50%;
		}

		form h1 {
			text-align: center;
		}

		.name,
		.address-info,
		.cc-info {
			flex-direction: column;
			width: 100%;
			justify-content: space-between;
		}
		.name div,
		.address-info div,
		.cc-info div {
			align-items: center;
			flex-direction: column;
			width: 100%;
			display: flex;
		}

		.street,
		.cc-num {
			text-align: center;
		}

		input {
			margin: 5px 0;
			min-height: 30px;
		}
	}
}

.column {
	display: flex;
	flex-direction: column;
}

.sent-button {
	background-color: #eb9478 !important;
}

.not-accepted-button {
	background-color: #e6a8df !important;
}

.manual-button {
	background-color: #90ee90 !important;
}
.hidden {
	display: "none";
}
